import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import { get, post, put } from "./fetch";
import LoadingButton from "./LoadingButton";

const UserEdit = () => {
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const { uuid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    uuid &&
      get(`/users/${uuid}`).then((data) => {
        setUsername(data.username || "");
        setFullName(data.fullName || "");
        // set(data.sku || "");
        setPhoneNumber(data.phoneNumber || "");
        setRole(data.role || "");
      });
  }, [uuid]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const obj = {
      username: username,
      fullName: fullName,
      password: password,
      phoneNumber: phoneNumber,
      role: role,
    };

    setLoading(true);
    if (uuid) {
      put(`/users/${uuid}`, obj)
        .then((_) => {
          navigate("/products");
        })
        .finally((_) => setLoading(false));
    } else {
      post("/users", obj)
        .then((_) => {
          setUsername("");
          setFullName("");
          setPassword("");
          setPhoneNumber("");
          setRole("");
        })
        .finally((_) => setLoading(false));
    }
  };

  return (
    <Row className="h-100 gx-0 align-items-center px-2 mt--7">
      <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
        <Card>
          <Card.Body>
            <Card.Title>{!!uuid ? "Измени" : "Креирај"} продукт</Card.Title>
            <Form onSubmit={handleSubmit}>
              <Row>
                <CustomInput
                  label={"Корисничко име"}
                  value={username}
                  setValue={setUsername}
                  options={{ required: true }}
                />
                <CustomInput
                  label={"Име"}
                  value={fullName}
                  setValue={setFullName}
                  options={{ required: true }}
                />
                <CustomInput
                  label={"Лозинка"}
                  value={password}
                  setValue={setPassword}
                  options={{ required: true }}
                />
                <CustomInput
                  label={"Телефон"}
                  value={phoneNumber}
                  setValue={setPhoneNumber}
                  asCol={true}
                  options={{ required: true }}
                />
                <CustomSelect
                  label={"Улога"}
                  value={role}
                  setValue={setRole}
                  values={["SYSTEM", "ADMIN", "USER"]}
                  options={{ required: true }}
                />
              </Row>
              <div className="d-flex">
                <LoadingButton
                  loading={loading}
                  text={!!uuid ? "Измени" : "Креирај"}
                />
                <Button
                  className="ms-2"
                  variant="secondary"
                  as={Link}
                  to="/users"
                >
                  Откажи
                </Button>
                {/* <Button
                  onClick={() => deleteProduct()}
                  variant="danger"
                  className="ms-auto px-2 py-1"
                >
                  Избриши
                </Button> */}
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default UserEdit;
