import { Col, Form } from "react-bootstrap";

const CustomInput = ({
  label,
  type,
  value,
  setValue,
  options,
  asCol,
  xs,
  sm,
  md,
  lg,
}) => {
  return (
    <Form.Group
      className="mb-3"
      as={asCol ? Col : "div"}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        {...options}
        type={type || "text"}
        placeholder={"Внеси " + (label && label.toLowerCase())}
        value={value}
        onChange={(e) =>
          setValue(type === "number" ? Number(e.target.value) : e.target.value)
        }
      />
    </Form.Group>
  );
};

export default CustomInput;
