import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  InputGroup,
  NavLink,
  Table,
} from "react-bootstrap";
import { get } from "./fetch";
import { Plus } from "react-feather";
import { Link } from "react-router-dom";

const Users = () => {
  const [text, setText] = useState("");
  const [searchMode, setSearchMode] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [items, setItems] = useState([]);

  const empty = () => {
    return searchMode ? filteredItems.length === 0 : items.length === 0;
  };

  useEffect(() => {
    get("/users").then((data) => {
      setItems(data);
    });
  }, []);

  const search = (term) => {
    if (!!term.trim()) {
      setText(term);
      setSearchMode(!!term);
      setFilteredItems(
        items.filter((item) =>
          ["name", "description"].reduce(
            (prev, curr) =>
              prev || item[curr].toLowerCase().includes(term.toLowerCase()),
            false,
          ),
        ),
      );
    } else {
      setText("");
      setSearchMode(false);
      setFilteredItems([]);
    }
  };

  return (
    <>
      <Container className="mt-4" style={{ height: "calc(100% - 10rem)" }}>
        <Form>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Внеси текст..."
              value={text}
              onChange={(e) => search(e.target.value)}
            />
            <Button as={Link} to={"/user"} variant="info" id="button-addon2">
              <Plus size={18} />
              Креирај корисник
            </Button>
          </InputGroup>
        </Form>
        <div style={{ overflow: "auto", height: "calc(100% - 3rem)" }}>
          <Table striped hover>
            <thead>
              <tr>
                <th>Име</th>
                <th>Корисничко име</th>
                <th>Телефон</th>
                <th>Улога</th>
              </tr>
            </thead>
            <tbody className="text-left align-middle">
              {(searchMode ? filteredItems : items).map((item) => (
                <tr key={item.uuid}>
                  <td className="fw-bold">
                    <NavLink as={Link} to={`/user/${item.uuid}`}>
                      {item.fullName}
                    </NavLink>
                  </td>
                  <td>{item.username}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.role}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {empty() && <p className="text-center">Не се пронајдени корисници</p>}
        </div>
      </Container>
    </>
  );
};

export default Users;
