const messages = {
  USERNAME_NOT_FOUND: "Погрешно корисничко име или лозинка",
  PRODUCT_OUT_OF_STOCK: "Еден или повеќе продукти не се на залиха",
  QUANTITY_MUST_BE_POSITIVE: "Количината мора да биде поголема од 0",
  GENERIC: "Oops. Something went wrong",
};

const get = (key) => {
  return messages[key] || messages["GENERIC"];
};

const errors = { get };

export { errors };
