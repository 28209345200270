import React, { Fragment } from "react";
import {
  Image,
  Text,
  View,
  Page,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxP.ttf",
      fontWeight: 100,
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAw.ttf",
      fontWeight: 300,
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf",
      fontWeight: 400,
    },
    {
      src: "(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAw.ttf",
      fontWeight: 500,
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAw.ttf",
      fontWeight: 700,
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAw.ttf",
      fontWeight: 900,
    },
  ],
});

const InvoicePdf = ({ invoice }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Roboto",
      fontSize: 11,
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },

    spaceBetween: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      color: "#3E3E3E",
    },

    titleContainer: { flexDirection: "row", marginTop: 24 },

    logo: { width: 120 },

    reportTitle: { fontSize: 9 },

    addressTitle: { fontSize: 10 },

    invoice: { fontSize: 9 },

    bold: { fontWeight: "bold" },

    address: { fontSize: 10 },

    theader: {
      fontSize: 10,
      paddingVertical: 5,
      paddingHorizontal: 5,
      flex: 1,
      backgroundColor: "#e8e8e8",
      borderColor: "white",
    },

    theader2: { flex: 2 },

    tbody: {
      fontSize: 9,
      paddingVertical: 5,
      paddingHorizontal: 5,
      flex: 1,
      borderColor: "white",
    },

    total: {
      fontSize: 9,
      paddingVertical: 5,
      paddingHorizontal: 5,
      flex: 1.5,
      borderColor: "white",
    },

    tbody2: { flex: 2 },
  });

  const InvoiceTitle = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <Image style={styles.logo} src="logo.png" />
        <View>
          <Text style={styles.reportTitle}>Avto Servis Kondinski</Text>
          <Text style={styles.reportTitle}>Mukos 160A, Bitola</Text>
          <Text style={styles.reportTitle}>076-595-179</Text>
        </View>
      </View>
    </View>
  );

  const Address = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View>
          <Text style={styles.invoice}>
            Фактура: <Text style={styles.bold}>{invoice.uuid}</Text>{" "}
          </Text>
          <Text style={styles.invoice}>
            Датум на издавање:{" "}
            <Text style={styles.bold}>
              {new Date(invoice.createdAt).toLocaleString("mk-MK")}
            </Text>
          </Text>
        </View>
      </View>
    </View>
  );

  const UserAddress = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View style={{ maxWidth: 200 }}>
          <Text style={styles.addressTitle}>
            Име на клиент: <Text style={styles.bold}>{invoice.buyerName}</Text>{" "}
          </Text>
          <Text style={styles.address}>
            Телефон на клиент:{" "}
            <Text style={styles.bold}>{invoice.buyerPhoneNumber}</Text>
          </Text>
        </View>
      </View>
    </View>
  );

  const TableHead = () => (
    <View style={{ width: "100%", flexDirection: "row" }}>
      <View style={[styles.theader, styles.theader2, styles.bold]}>
        <Text>Продукт</Text>
      </View>
      <View style={[styles.theader, styles.bold]}>
        <Text>Цена</Text>
      </View>
      <View style={[styles.theader, styles.bold]}>
        <Text>Количина</Text>
      </View>
      <View style={[styles.theader, styles.bold]}>
        <Text>Вкупна цена</Text>
      </View>
    </View>
  );

  const TableBody = () =>
    invoice.items.map((item) => (
      <Fragment key={item.uuid}>
        <View style={{ width: "100%", flexDirection: "row" }}>
          <View style={[styles.tbody, styles.tbody2]}>
            <Text>
              {item.name} ({item.sku})
            </Text>
          </View>
          <View style={styles.tbody}>
            <Text>
              {item.price.toLocaleString("mk-MK", {
                style: "currency",
                currency: "MKD",
              })}{" "}
            </Text>
          </View>
          <View style={styles.tbody}>
            <Text>{item.quantity}</Text>
          </View>
          <View style={styles.tbody}>
            <Text>
              {(item.price * item.quantity).toLocaleString("mk-MK", {
                style: "currency",
                currency: "MKD",
              })}
            </Text>
          </View>
        </View>
      </Fragment>
    ));

  const TableTotal = () => (
    <View style={{ width: "100%", flexDirection: "row" }}>
      <View style={styles.total}>
        <Text></Text>
      </View>
      <View style={styles.total}>
        <Text> </Text>
      </View>
      <View style={styles.tbody}>
        <Text style={styles.bold}>Вкупно</Text>
      </View>
      <View style={styles.tbody}>
        <Text style={styles.bold}>
          {invoice.items
            .reduce((sum, item) => sum + item.price * item.quantity, 0)
            .toLocaleString("mk-MK", {
              style: "currency",
              currency: "MKD",
            })}
        </Text>
      </View>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceTitle />
        <Address />
        <UserAddress />
        <TableHead />
        <TableBody />
        <TableTotal />
      </Page>
    </Document>
  );
};
export default InvoicePdf;
