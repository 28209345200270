import { useState } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import CustomInput from "./CustomInput";
import { post } from "./fetch";
import LoadingButton from "./LoadingButton";
import { useNavigate } from "react-router-dom";
import { useUserActionsContext } from "./UserContext";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useUserActionsContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    post("/login", { username, password }, true)
      .then(({ accessToken }) => {
        login(accessToken);
        navigate("/");
      })
      .finally((_) => setLoading(false));
  };

  return (
    <Row className="h-100 gx-0 align-items-center px-2 mt--7">
      <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
        <Card>
          <Card.Body>
            <Card.Title>Најава</Card.Title>
            <Form onSubmit={handleSubmit}>
              <Row>
                <CustomInput
                  label="Корисничко име"
                  value={username}
                  setValue={setUsername}
                  options={{ required: true }}
                />
                <CustomInput
                  label="Лозинка"
                  type="password"
                  value={password}
                  setValue={setPassword}
                  options={{ required: true }}
                />
              </Row>
              <LoadingButton loading={loading} text={"Најава"} />
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
