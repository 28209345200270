import { Col, Form } from "react-bootstrap";

const CustomSelect = ({
  label,
  type,
  value,
  setValue,
  options,
  values,
  asCol,
  xs,
  sm,
  md,
  lg,
}) => {
  return (
    <Form.Group
      className="mb-3"
      as={asCol ? Col : "div"}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Select
        {...options}
        type={type || "text"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        <option value="" disabled>
          {"Изберете"}
        </option>
        {values.map((it) => (
          <option key={it}>{it}</option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default CustomSelect;
